import React from 'react'
import { getPictureProps, theme } from '@superhi/design'
import { Breakpoint } from '@superhi/design/dist/cjs/theme/media'

import { Wrapper, BackgroundImage } from './styles'

type Color = 'blue' | 'yellow'

const CONFIG: Record<
  Color,
  {
    imageUrl: string
    blurHash: string
    backgroundColor: keyof typeof theme.colors
  }
> = {
  blue: {
    imageUrl:
      'https://superhi.s3.amazonaws.com/assets/images/unlimited-membership/projects-background-large.png',
    backgroundColor: 'WARM_BLUE_50',
    blurHash: 'eMKDlAxaR*a|j[0fNHayj[fQR*fQayj[j[j[oej[jtfQayfQj@jtj@',
  },
  yellow: {
    imageUrl:
      'https://superhi.s3.amazonaws.com/assets/images/unlimited-membership/community-background-large.png',
    backgroundColor: 'YELLOW_40',
    blurHash: 'eJTR@Wj]eljse-lCf6fhf6fPe?f5f8fkf6k9fPf+fkfQe.f6fkf6fk',
  },
}

type Props = {
  color?: Color
  options?: Record<Breakpoint, Parameters<typeof getPictureProps>['0']['small']['options']>
  position?: 'top center' | 'bottom center'
}

const BackgroundImageWarped: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  color = 'yellow',
  position = 'top center',
  options,
}) => {
  const config = React.useMemo(
    () => ({
      image: getPictureProps({
        small: {
          path: CONFIG[color].imageUrl,
          width: 100,
          options: {
            fm: 'jpg',
            ...(options?.small || {}),
          },
        },
        medium: {
          path: CONFIG[color].imageUrl,
          width: 100,
          options: {
            fm: 'jpg',
            ...(options?.medium || {}),
          },
        },
        large: {
          path: CONFIG[color].imageUrl,
          width: 100,
          options: {
            fm: 'jpg',
            ...(options?.large || {}),
          },
        },
        xlarge: {
          path: CONFIG[color].imageUrl,
          width: 100,
          options: {
            fm: 'jpg',
            ...(options?.xlarge || {}),
          },
        },
      }),
      color: CONFIG[color].backgroundColor,
      blurHash: CONFIG[color].blurHash,
    }),
    [color, options],
  )

  return (
    <Wrapper>
      <BackgroundImage
        {...config.image}
        blurHash={config.blurHash}
        backgroundColor={config.color}
        fit="cover"
        position={position}
        alt=""
        lazy
      />
      {children}
    </Wrapper>
  )
}

export default BackgroundImageWarped
