import { GetStaticProps } from 'next'

import query from './queries'

import initApollo, { API } from 'src/api'

export type Props = API.Home

export const getStaticProps: GetStaticProps<Props> = async () => {
  const client = initApollo({ cookies: {} })

  const { data } = await client.query<API.Home>({
    query,
  })

  return {
    props: data,
  }
}
