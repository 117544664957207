import React from 'react'
import { Grid, PageSection as Section, Button, TextButton, Lazy, Lockup } from '@superhi/design'

import { Wrapper, Video, VideoWrapper, Progress } from './styles'
import { DATA } from './config'

import { buildPath } from 'src/routing'

const EDITOR_PATH = buildPath('editor')
const CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: [2, 7],
  large: 5,
}
const CELL_ORDER_1 = { small: 2, medium: 2 }
const ROW_GAP_1 = { small: 24, medium: 40 }
const CELL_SPAN_2: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: 'full',
  large: 7,
}

type Props = Pick<
  React.ComponentProps<typeof Section>,
  | 'decorationName'
  | 'decorationPosition'
  | 'collapseBottomPadding'
  | 'collapseTopPadding'
  | 'noBottomPadding'
  | 'noTopPadding'
> & {
  title?: string
  subtitle?: string
  headingLevel?: number
  subheadingLevel?: number
}

const Editor: React.FC<React.PropsWithChildren<Props>> = ({
  title = 'The SuperHi Editor',
  subtitle = 'Our intelligent code editor is designed to help making websites from scratch easier. Comes with any course, or sign up for a free SuperHi account for access.',
  decorationName,
  decorationPosition,
  collapseBottomPadding,
  collapseTopPadding,
  noBottomPadding,
  noTopPadding,
  headingLevel = 2,
  subheadingLevel = 3,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [currentProgress, setCurrentProgress] = React.useState(0)
  const currentItem = DATA[currentIndex]

  const setCurrentVideo = (index: number) => {
    setCurrentIndex(index)
    setCurrentProgress(0)
  }

  const handleVideoEnded = () => {
    setCurrentVideo((currentIndex + 1) % DATA.length)
  }

  const handleTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    e.persist()
    const newCurrentProgress = e.currentTarget.currentTime / e.currentTarget.duration
    setCurrentProgress(Number.isNaN(newCurrentProgress) ? 0 : newCurrentProgress)
  }

  return (
    <Section
      title={title}
      subtitle={subtitle}
      footer={<Button href={EDITOR_PATH}>Learn more about the Editor</Button>}
      decorationName={decorationName}
      decorationPosition={decorationPosition}
      collapseBottomPadding={collapseBottomPadding}
      collapseTopPadding={collapseTopPadding}
      noBottomPadding={noBottomPadding}
      noTopPadding={noTopPadding}
      headingLevel={headingLevel}
    >
      <Grid rowGap={ROW_GAP_1}>
        <Grid.Cell order={CELL_ORDER_1} span={CELL_SPAN_1}>
          <Wrapper>
            {DATA.map((data, i) => (
              <TextButton
                key={i}
                onClick={() => setCurrentVideo(i)}
                color="GREY_90"
                hoverColor="BLUE_50"
              >
                <Lockup
                  {...data}
                  level="4"
                  titleColor={currentIndex === i ? 'BLUE_50' : 'currentColor'}
                  titleMaxWidth={20}
                  subtitleMaxWidth={100}
                  align="left"
                  headingLevel={subheadingLevel}
                />
              </TextButton>
            ))}
          </Wrapper>
        </Grid.Cell>

        <Grid.Cell span={CELL_SPAN_2}>
          <Lazy triggerOnce>
            {({ ref, inView }) => (
              <VideoWrapper ref={ref}>
                {inView && (
                  <Video
                    src={currentItem.videoUrl}
                    onEnded={handleVideoEnded}
                    onTimeUpdate={handleTimeUpdate}
                    muted
                    autoPlay
                    playsInline
                  />
                )}
                <Progress $value={currentProgress} />
              </VideoWrapper>
            )}
          </Lazy>
        </Grid.Cell>
      </Grid>
    </Section>
  )
}

export default Editor
