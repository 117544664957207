import React from 'react'
import { Lockup, DecorativeShape, Card } from '@superhi/design'

import { Wrapper } from './styles'

type Props = React.ComponentProps<typeof Lockup> & {
  shapeName: React.ComponentProps<typeof DecorativeShape>['name']
  headingLevel?: number
} & Pick<React.ComponentProps<typeof Card>, 'elevated'>

const HighlightCard: React.FC<React.PropsWithChildren<Props>> = ({
  shapeName,
  subtitle,
  title,
  titleMaxWidth,
  subtitleMaxWidth,
  elevated = false,
  headingLevel = 3,
}) => (
  <Card level="1" elevated={elevated}>
    <Wrapper>
      <DecorativeShape name={shapeName} />
      <Lockup
        level="4"
        title={title}
        titleMaxWidth={titleMaxWidth}
        subtitle={subtitle}
        subtitleMaxWidth={subtitleMaxWidth}
        headingLevel={headingLevel}
      />
    </Wrapper>
  </Card>
)

export default HighlightCard
