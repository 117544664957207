import React from 'react'
import { Button, getPictureProps, Lockup, VerticalSpacer } from '@superhi/design'

import { Wrapper, BackgroundImage } from './styles'

import { buildPath } from 'src/routing'
import PageHeaderHero from 'src/components/page-headers/PageHeaderHero'

const COURSES_PATH = buildPath('courses-index')

const backgroundImageProps = getPictureProps({
  small: {
    path: 'https://superhi.s3.amazonaws.com/assets/images/home-hero-small.png',
    width: 100,
    options: {
      fm: 'png8',
    },
  },
  medium: {
    path: 'https://superhi.s3.amazonaws.com/assets/images/home-hero-medium.png',
    width: 100,
    options: {
      fm: 'png8',
    },
  },
  large: {
    path: 'https://superhi.s3.amazonaws.com/assets/images/home-hero-large.png',
    width: 100,
    options: {
      fm: 'png8',
    },
  },
  xlarge: {
    path: 'https://superhi.s3.amazonaws.com/assets/images/home-hero-large.png',
    width: 100,
    options: {
      fm: 'png8',
    },
  },
})

const Hero: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Wrapper>
    <BackgroundImage
      {...backgroundImageProps}
      blurHash="emNTe1uhJDiwtPY5oywHVZkWM}rWXmS5oeowX9oJWEs8a$jFkCaxn$"
      fit="cover"
      alt=""
    />

    <PageHeaderHero
      header={
        <>
          <VerticalSpacer amount={{ small: 32, medium: 40, large: 48 }}>
            <Lockup
              title="We help the best&nbsp;creatives get&nbsp;technical"
              titleMaxWidth={12}
              subtitle="We make online courses that help creative people stand out. Learn how to code, design, and manage projects among our global&nbsp;community!"
              subtitleMaxWidth={22}
              align={{ small: 'center', medium: 'center', large: 'left' }}
            />

            <Button level="1" href={COURSES_PATH}>
              View our courses
            </Button>
          </VerticalSpacer>
        </>
      }
    />
  </Wrapper>
)

export default Hero
