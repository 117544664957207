import React from 'react'
import { Grid, PageSection as Section } from '@superhi/design'

import HighlightCard from '../../HighlightCard'
import BackgroundImageWarped from '../../BackgroundImageWarped'

const PROJECTS: React.ComponentProps<typeof HighlightCard> = {
  title: '“Show, don’t tell”',
  subtitle:
    'Our expert-led courses are centered around portfolio projects that speak louder than bullet points on a résumé.',
  shapeName: 'star-yellow',
}

const SKILLS: React.ComponentProps<typeof HighlightCard> = {
  title: 'Pick up practical skills',
  subtitle:
    "We teach real-world skills you'll actually use, whether it’s to level up at your current job or get a new one.",
  shapeName: 'wedge-red',
}

const TECHNOLOGY: React.ComponentProps<typeof HighlightCard> = {
  title: 'Design-led technology',
  subtitle:
    'We use beautiful, easy-to-use tools to help creative people learn more quickly and produce their best work.',
  shapeName: 'pentagon-blue',
}

const COMMUNITY: React.ComponentProps<typeof HighlightCard> = {
  title: 'Global community',
  subtitle:
    'We nurture an international, diverse community of creative professionals who learn together online.',
  shapeName: 'square-green',
}

const DATA: React.ComponentProps<typeof HighlightCard>[] = [PROJECTS, TECHNOLOGY, COMMUNITY]

const GRID_1_CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: [2, 7],
  large: 4,
}

const BACKGROUND_IMAGE_OPTIONS: React.ComponentProps<typeof BackgroundImageWarped>['options'] = {
  small: {
    ar: '50:100',
    fit: 'crop',
    crop: 'bottom',
  },
  medium: {
    ar: '75:100',
    fit: 'crop',
    crop: 'bottom',
  },
  large: {
    ar: '100:50',
    fit: 'crop',
    crop: 'bottom',
  },
  xlarge: {
    ar: '125:50',
    fit: 'crop',
    crop: 'bottom',
  },
}

const TheSuperHiWay = () => (
  <BackgroundImageWarped color="yellow" position="top center" options={BACKGROUND_IMAGE_OPTIONS}>
    <Section
      title="The SuperHi Way"
      subtitle="We&rsquo;re different from other education platforms&thinsp;&mdash;&thinsp;we take a creative approach to teaching technical skills."
      backgroundColor="transparent"
      headingLevel={2}
    >
      <Grid alignItems="stretch">
        {DATA.map((item) => (
          <Grid.Cell key={item.title} span={GRID_1_CELL_SPAN_1}>
            <HighlightCard {...item} titleMaxWidth={10} subtitleMaxWidth={32} headingLevel={3} />
          </Grid.Cell>
        ))}
      </Grid>
    </Section>
  </BackgroundImageWarped>
)

export default TheSuperHiWay
