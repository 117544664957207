import React from 'react'
import { getImgProps, StudentWorkPreview as StudentWorkCardComponent } from '@superhi/design'

import { buildPath } from 'src/routing'
import { API } from 'src/api'

type ComponentProps = Omit<
  React.ComponentProps<typeof StudentWorkCardComponent>,
  'href' | 'by' | 'type' | 'image'
>

type Props = ComponentProps &
  API.StudentWorkCardComponent & {
    imageLazy?: boolean
  }

const StudentWorkCard: React.FC<React.PropsWithChildren<Props>> = ({
  slug,
  users,
  image,
  imageLazy = false,
  ...props
}) => {
  const imageProps = React.useMemo(
    () => ({
      ...getImgProps(
        image.url,
        {
          small: 80,
          medium: 50,
          large: 33,
          xlarge: 33,
        },
        {
          fit: 'crop',
          ar: '16:9',
          crop: 'top',
          fm: 'png8',
        },
      ),
      lazy: imageLazy,
      blurHash: image.blurhash,
    }),
    [image, imageLazy],
  )

  const href = React.useMemo(() => buildPath('hall-of-fame-view', { params: { slug } }), [slug])

  return (
    <StudentWorkCardComponent
      {...props}
      href={href}
      by={users?.map((user) => user.name || user.username).join(', ') || 'unknown'}
      image={imageProps}
    />
  )
}

export { default as FRAGMENT } from './fragments'
export default StudentWorkCard
