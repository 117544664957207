import React from 'react'
import { PageSection as Section, Button, Grid } from '@superhi/design'

import StudentWorkCard from '../../StudentWorkCard'

import { API } from 'src/api'

const GRID_SLIDER_1 = { small: true, medium: true }
const CELL_SPAN_1 = { small: 3, medium: 4, large: 4 }

type Props = API.HallOfFameComponent &
  Pick<
    React.ComponentProps<typeof Section>,
    'collapseTopPadding' | 'collapseBottomPadding' | 'decorationName' | 'decorationPosition'
  > & {
    title?: string
    subtitle?: string
    buttonText?: string
    buttonHref?: string
    type?: 'WEBSITE' | 'DESIGN'
    imagesLazy?: boolean
  }

const HallOfFame: React.FC<React.PropsWithChildren<Props>> = ({
  title = 'The Hall of Fame',
  subtitle = 'We’re really proud of all the amazing work our students have made after taking our courses. Have a look below!',
  buttonText = 'View all student work',
  buttonHref = '/hall-of-fame',
  type,
  collapseTopPadding,
  collapseBottomPadding,
  decorationName,
  decorationPosition,
  imagesLazy = false,
  nodes,
}) => {
  const hallOfFamers = nodes || []

  return (
    <Section
      title={title}
      subtitle={subtitle}
      footer={<Button href={buttonHref}>{buttonText}</Button>}
      collapseTopPadding={collapseTopPadding}
      collapseBottomPadding={collapseBottomPadding}
      decorationName={decorationName}
      decorationPosition={decorationPosition}
      headingLevel={2}
    >
      <Grid slider={GRID_SLIDER_1} alignItems="stretch">
        {hallOfFamers.map((item) => (
          <Grid.Cell key={item.slug} span={CELL_SPAN_1}>
            <StudentWorkCard {...item} imageLazy={imagesLazy} headingLevel={3} />
          </Grid.Cell>
        ))}
      </Grid>
    </Section>
  )
}

export default HallOfFame
