import { Picture, theme } from '@superhi/design'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;

  @media ${theme.breakpoints.medium} {
    display: block;
    min-height: 50vh;
  }

  @media ${theme.breakpoints.large} {
    min-height: 75vh;
  }

  @media ${theme.breakpoints.xlarge} {
    min-height: 75vh;
  }
`

export const BackgroundImage = styled(Picture)`
  background-color: ${theme.colors.WARM_BLUE_30};
  position: absolute;
  top: 0;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
