import { theme, VerticalSpacer } from '@superhi/design'
import styled from 'styled-components'

import { Wrapper as WrapperBase, WrapperProps as WrapperBaseProps } from '../styles'

export const Wrapper = styled(WrapperBase)``

type ContentProps = {
  $align?: WrapperBaseProps['$align']
}

export const Content = styled(VerticalSpacer).attrs({
  amount: { small: 24, medium: 32, large: 40 },
})<ContentProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media ${theme.breakpoints.large} {
    align-items: ${({ $align = 'center' }) => ($align === 'center' ? 'center' : 'flex-start')};
    text-align: ${({ $align = 'center' }) => ($align === 'center' ? 'center' : 'left')};
  }
`
