import { gql } from '@apollo/client'

import { FRAGMENT as STUDENT_WORK_FRAGMENT } from '../../StudentWorkCard'

export default gql`
  fragment HallOfFameComponent on HallOfFamerList {
    nodes {
      ...StudentWorkCardComponent
    }
  }

  ${STUDENT_WORK_FRAGMENT}
`
