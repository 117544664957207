import React from 'react'

import Hero from './components/Hero'
import GlobalOnlineCampus from './components/GlobalOnlineCampus'
import { Props } from './data'
import ROUTE from './route'

import SuperHiPlusCallout from 'src/components/PlusCallout'
import Editor from 'src/components/sections/Editor'
import FAQs from 'src/components/sections/HelpCenterArticles'
import SuperLibraryGuides from 'src/components/sections/SuperLibraryGuides'
import HallOfFame from 'src/components/sections/HallOfFame'
import Aside from 'src/components/Aside'
import Main from 'src/components/Main'
import Page from 'src/components/Page'
import TheSuperHiWay from 'src/components/sections/TheSuperHiWay'
import TopCourses from 'src/components/sections/TopCourses'
import { PageConfig } from 'src/routing/types'
import Signup from 'src/components/Signup'

const Home: PageConfig<Props> = ({ topCourses, topGuides, hallOfFame, faqs }) => (
  <Page>
    <Main>
      <Hero />

      {topCourses && (
        <TopCourses
          {...topCourses}
          subtitle="We don&rsquo;t pick favorites&thinsp;&mdash;&thinsp;but you do! Here are our most popular courses taken by thousands, from the ultra experienced to the absolute beginner."
          imagesLazy
        />
      )}

      <TheSuperHiWay />

      <Editor collapseBottomPadding headingLevel={2} />

      {topGuides && (
        <SuperLibraryGuides
          {...topGuides}
          decorationName="pentagon"
          decorationPosition="top-right"
          imagesLazy
          collapseTopPadding
        />
      )}

      <GlobalOnlineCampus />

      {hallOfFame && (
        <HallOfFame
          {...hallOfFame}
          decorationName="triangle"
          decorationPosition="bottom-left"
          imagesLazy
          collapseBottomPadding
        />
      )}

      <Signup />

      {faqs && <FAQs {...faqs} title="Questions we get asked a lot..." collapseTopPadding />}
    </Main>
  </Page>
)

Home.route = ROUTE

export { getStaticProps } from './data'
export default Home
