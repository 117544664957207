import styled from 'styled-components'
import { theme, VerticalSpacer } from '@superhi/design'

export const Wrapper = styled(VerticalSpacer).attrs({
  amount: { small: 24, medium: 24, large: 32 },
})`
  display: flex;
  flex-direction: column;
`

export const VideoWrapper = styled.div`
  position: relative;
  padding-top: 66.66666666666666%;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid ${theme.colors.GREY_50};
`

type VideoWrapperProps = {
  $value: number
}

export const Progress = styled.div.attrs<VideoWrapperProps>(({ $value }) => ({
  style: {
    transform: `scaleX(${$value})`,
  },
}))<VideoWrapperProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${theme.colors.WARM_BLUE_50};
  height: 8px;
  transform-origin: center left;
  transition: transform ${({ $value }) => ($value === 0 ? 0 : 0.25)}s linear;
`

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
