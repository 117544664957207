import { theme } from '@superhi/design'
import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  margin-top: 8px;

  @media ${theme.breakpoints.medium} {
    margin-top: 0;
  }
`
