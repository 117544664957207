import React from 'react'
import { Body, Grid, Heading, PageSection as Section } from '@superhi/design'

import { Wrapper, Item } from './styles'

import BackgroundImageWarped from 'src/components/BackgroundImageWarped'

const DATA = [
  {
    title: '10k',
    subtitle: 'students',
  },
  {
    title: '90+',
    subtitle: 'countries',
  },
  {
    title: '30k',
    subtitle: 'courses taken',
  },
]

const GRID_1_CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: 'full',
  large: [3, 10],
}

const BACKGROUND_IMAGE_OPTIONS: React.ComponentProps<typeof BackgroundImageWarped>['options'] = {
  small: {
    ar: '50:100',
    fit: 'crop',
    crop: 'bottom',
  },
  medium: {
    ar: '100:50',
    fit: 'crop',
    crop: 'bottom',
  },
  large: {
    ar: '100:50',
    fit: 'crop',
    crop: 'bottom',
  },
  xlarge: {
    ar: '125:50',
    fit: 'crop',
    crop: 'bottom',
  },
}

const GlobalOnlineCampus = () => (
  <BackgroundImageWarped color="blue" position="top center" options={BACKGROUND_IMAGE_OPTIONS}>
    <Section
      title="A global, online campus"
      subtitle="Our courses have been taken by students in over 90 countries and across 16 timezones. When you join a paid SuperHi course, you join the community too. Come hang out with us and talk about careers, music, design, anything&thinsp;&mdash;&thinsp;and get help and support as you learn."
      backgroundColor="transparent"
      headingLevel={2}
    >
      <Grid>
        <Grid.Cell span={GRID_1_CELL_SPAN_1}>
          <Wrapper>
            {DATA.map((item) => (
              <Item key={item.title}>
                <Heading level="1" as={'span'}>
                  {item.title}
                </Heading>
                <Body level="1" as={'span'}>
                  {item.subtitle}
                </Body>
              </Item>
            ))}
          </Wrapper>
        </Grid.Cell>
      </Grid>
    </Section>
  </BackgroundImageWarped>
)

export default GlobalOnlineCampus
