import { Lockup } from '@superhi/design'

export const DATA: (React.ComponentProps<typeof Lockup> & { videoUrl: string })[] = [
  {
    title: 'Designed with beginners in mind',
    subtitle:
      'We help you get started making websites right away, without worrying about all the technical stuff.',
    videoUrl:
      'https://superhi-editor.s3.us-east-1.amazonaws.com/videos/editor-features/beginner-friendly.mp4',
  },
  {
    title: 'Publish with one click',
    subtitle:
      'Switch between code and preview modes, password protect your sites, add custom domain names. Publish live in seconds.',
    videoUrl:
      'https://superhi-editor.s3.us-east-1.amazonaws.com/videos/editor-features/one-place.mp4',
  },
  {
    title: 'Host it all in one place',
    subtitle:
      'Host unlimited projects and all your project files in one place. They’ll all have an easy to access SuperHi URL like yourproject.superhi.com — and you can add your own 3rd party custom yournamehere.com domain too.',
    videoUrl:
      'https://superhi-editor.s3.us-east-1.amazonaws.com/videos/editor-features/one-click-publish.mp4',
  },
]
