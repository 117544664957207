import { useMutation } from '@apollo/client'
import { Form, FormTextInput, Callout, Button } from '@superhi/design'
import React from 'react'
import { string } from 'yup'
import { setErrorsFromApollo } from '@superhi/utils'

import { ButtonWrapper } from './styles'
import { LEAD_CREATE } from './mutations'
import { EMAIL } from './config'

import { API } from 'src/api'

type FormValues = {
  email: string
}

type Props = {}

const Signup: React.FC<React.PropsWithChildren<Props>> = ({}) => {
  const [mutation, result] = useMutation<API.Signup, API.SignupVariables>(LEAD_CREATE)

  const signedUp = result.called && !result.loading && result.data?.leadCreate

  return (
    <Callout
      data-component-name="Signup"
      title={!signedUp ? 'Stay in touch! Sign up for our newsletter' : "You're all signed up!"}
      titleMaxWidth={18}
      backgroundColor="red"
      headingLevel={2}
    >
      {!signedUp && (
        <Form<FormValues>
          name="newsletter"
          initialValues={{ email: '' }}
          validationSchema={{ email: string().required() }}
          onSubmit={async (values, helpers) => {
            try {
              await mutation({
                variables: {
                  tag: `newsletter`,
                  email: values.email,
                },
              })
            } catch (e) {
              setErrorsFromApollo(helpers.setFieldError, e)
            }
          }}
          submitButtonText="Sign me up"
          submitButtonAlign="right"
          spacing={{ small: 8, medium: 8, large: 8 }}
          submitButtonIsCustom
        >
          <Form.Split ratios={{ small: 'full', medium: [60, 40], large: [60, 40] }}>
            <FormTextInput {...EMAIL} />
            <ButtonWrapper>
              <Button type="submit">Sign me up</Button>
            </ButtonWrapper>
          </Form.Split>
        </Form>
      )}
    </Callout>
  )
}

export default Signup
