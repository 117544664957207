import { Grid } from '@superhi/design'
import React from 'react'

import { WrapperProps } from '../styles'

import { Wrapper, Content } from './styles'

const GRID_1_CELL_SPAN_1: Record<
  WrapperProps['$align'],
  React.ComponentProps<typeof Grid.Cell>['span']
> = {
  left: { small: 'full', medium: 'full', large: 7, xlarge: 6 },
  center: { small: 'full', medium: 'full', large: [2, 11], xlarge: [3, 10] },
}

const GRID_1_ROW_GAP = { small: 32, medium: 48 }

type Props = {
  header: React.ReactElement
  footer?: React.ReactElement
  align?: WrapperProps['$align']
}

export const PageHeaderHero: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  footer,
  align = 'left',
  ...commonProps
}) => (
  <Wrapper data-component-name="PageHeaderHero" {...commonProps} $align={align} $compact={false}>
    <Grid rowGap={GRID_1_ROW_GAP}>
      <Grid.Cell span={GRID_1_CELL_SPAN_1[align]}>
        <Content $align={align}>
          <div>{header}</div>

          {footer && <div>{footer}</div>}
        </Content>
      </Grid.Cell>
    </Grid>
  </Wrapper>
)

export default PageHeaderHero
