import { gql } from '@apollo/client'

export default gql`
  fragment StudentWorkCardComponent on HallOfFamer {
    slug
    title
    image {
      url
      blurhash
    }
    type
    users {
      name
      username
    }
  }
`
