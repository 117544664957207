import { theme } from '@superhi/design'
import { ResponsiveBreakpoint } from '@superhi/design/dist/cjs/theme/media'
import styled from 'styled-components'

const PADDING: Record<ResponsiveBreakpoint, number> = {
  small: 52,
  medium: 112,
  large: 136,
}

const PADDING_COMPACT: Record<ResponsiveBreakpoint, number> = {
  small: 32,
  medium: 40,
  large: 64,
}

export type Align = 'left' | 'center'

export type WrapperProps = {
  $compact: boolean
  $align: Align
  $backgroundColor?: keyof typeof theme.colors
}

const WRAPPER_JUSTIFY_CONTENT: Record<WrapperProps['$align'], string> = {
  left: 'flex-start',
  center: 'center',
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  padding: ${({ $compact }) => `${$compact ? PADDING_COMPACT.small : PADDING.small}px 0`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? theme.colors[$backgroundColor] : ''};

  @media ${theme.breakpoints.medium} {
    padding: ${({ $compact }) => `${$compact ? PADDING_COMPACT.medium : PADDING.medium}px 0`};
    justify-content: center;
  }

  @media ${theme.breakpoints.large} {
    padding: ${({ $compact }) => `${$compact ? PADDING_COMPACT.large : PADDING.large}px 0`};
    justify-content: ${({ $align }) => WRAPPER_JUSTIFY_CONTENT[$align]};
  }
`
