import styled from 'styled-components'
import { theme } from '@superhi/design'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${theme.breakpoints.medium} {
    align-items: flex-start;
    flex-direction: row;
  }

  > * {
    width: 100%;

    @media ${theme.breakpoints.medium} {
      width: calc(33.3333%);
    }
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  &:not(:last-child) {
    &:after {
      content: '';
      background-color: ${theme.colors.BLUE_50};
      height: 2px;
      margin: 24px 0;
      width: 25%;
      min-width: 125px;

      @media ${theme.breakpoints.medium} {
        min-width: auto;
        margin: 0;
        position: absolute;
        top: 0;
        left: calc(100% - 2px);
        height: 100%;
        width: 4px;
      }
    }
  }
`
