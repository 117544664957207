import { gql } from '@apollo/client'

import TOP_COURSES_FRAGMENT from 'src/components/sections/TopCourses/fragments'
import TOP_GUIDES_FRAGMENT from 'src/components/sections/SuperLibraryGuides/fragments'
import HOF_FRAGMENT from 'src/components/sections/HallOfFame/fragments'
import HELP_CENTER_ARTICLES_FRAGMENT from 'src/components/sections/HelpCenterArticles/fragments'

export default gql`
  query Home {
    topCourses: productCourses(
      perPage: 6
      isBestSeller: true
      sort: { direction: ASC, key: ORDER }
    ) {
      ...TopCoursesComponent
    }

    topGuides: libraryGuides(perPage: 3) {
      ...SuperLibraryGuidesComponent
    }

    hallOfFame: hallOfFamers(perPage: 6) {
      ...HallOfFameComponent
    }

    faqs: helpCenterArticles(topicSlug: "commonly-asked-questions") {
      ...HelpCenterArticlesComponent
    }
  }

  ${TOP_COURSES_FRAGMENT}
  ${TOP_GUIDES_FRAGMENT}
  ${HOF_FRAGMENT}
  ${HELP_CENTER_ARTICLES_FRAGMENT}
`
