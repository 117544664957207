import { Picture } from '@superhi/design'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const BackgroundImage = styled(Picture)`
  position: absolute;
  top: 0;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
